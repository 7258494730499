import React from "react";
import logo from "../assets/image/png/logo.png";
const Loading = () => {
  return (
    <div className="min-vh-100 bg-white loding_position">
      <img
        className="w_xsm_50 w_sm_13 animation_loding"
        src={logo}
        alt="loading"
      />
    </div>
  );
};

export default Loading;
