import React from "react";
import { Container } from "react-bootstrap";
import image_0 from "../assets/image/png/image-1.png";
import star from "../assets/image/png/star.png";
import star_1 from "../assets/image/png/star_1.png";
import img from "../assets/image/png/img.png";
import goll from "../assets/image/png/goll.png";
import { Link } from "react-router-dom";

function International() {
  return (
    <>
      <section className="bg_img d-flex flex-column position-relative min-vh-100 ">
        {/* <img
          className="position-abso1 d-lg-flex d-none star_animate"
          src={star_1}
          alt="star"
        /> */}
        {/* <img
          className="position-abso2 star_animate d-lg-flex d-none"
          src={img}
          alt="star"
        /> */}
        <div className="bg_blue py-4">
          <h2 className="fs_20 fw_700 ff_karla text-white text-center mb-0">
            Real Assets beyond Real Estate
          </h2>
        </div>
        <div className="d-flex justify-content-center flex-grow-1 flex-column">
          {" "}
          <Container className="position-relative">
            {/* <img
              className="position-absolute star_animate d-lg-flex d-none top-0 start-0"
              src={star}
              alt="star"
            /> */}
            {/* <img
              className="position-absolute star_animate d-lg-flex d-none bottom-0 start-0"
              src={goll}
              alt="star"
            /> */}
            <div className="box_1 text-center mx-auto">
              <div className="d-flex justify-content-center">
                <Link to="/">
                  <img className="w_100" src={image_0} alt="image_0" />
                </Link>
              </div>
              <div className="d-flex mt-5 align-items-center justify-content-center">
                <Link to="/login">
                  <div className="d-flex">
                    <h2 className="fs_16 fw_700 ff_karla mb-0 text-black">
                      Investors
                    </h2>
                    <div className="border ms-2 "></div>
                  </div>
                </Link>
                <Link to="/login">
                  <div className="d-flex">
                    <h2 className="fs_16 fw_700 ff_karla mb-0 ps-2 text-black">
                      Clients
                    </h2>
                    <div className="border ms-2 "></div>
                  </div>
                </Link>
                <Link to="/login">
                  <div className="d-flex">
                    <h2 className="fs_16 fw_700 ff_karla mb-0 ps-2 text-black">
                      Partners
                    </h2>
                    <div className="border ms-2 "></div>
                  </div>
                </Link>
              </div>
              <p className="ff_karla fw_400 fs_16 text-black text-center mb-0 mt-4">
                General correspondence and requests:
              </p>
              <p className="ff_karla fw_400 fs_16 text-black text-center mb-0 mt-1">
                info@hcapitalinternational.com
              </p>
              <p className="ff_karla fw_700 fs_16 text-black text-center mb-0 mt-4">
                We are headquartered in Ikoyi, Lagos
              </p>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
}

export default International;
