import React, { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { Col, Container, Row } from "react-bootstrap";
import capital from "../assets/image/png/capital.png";
import Victor from "../assets/image/png/Vector.png";
import img from "../assets/image/png/img.png";

const Capital = () => {
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".second_sec",
          start: "top 40%",
          // markers: true,
          scrub: 1,
        },
      })
      .fromTo(
        ".hci_img",
        {
          translateX: "-30px",
        },
        {
          translateX: "0px",
          visibility: "visible",
        }
      )
      .fromTo(
        ".capital_text",
        {
          translateX: "30px",
        },
        {
          translateX: "0px",
          visibility: "visible",
        },
        "-=0.5"
      );
  }, []);
  return (
    <>
      <section className="py-md-5 mt-5 position-relative second_sec">
        {/* <img
          className="position-abso-new star_animate d-lg-flex d-none"
          src={img}
          alt="star"
        />
        <img
          className="position-absolute animation_translate top-0 start-0  d-none d-md-flex"
          src={Victor}
          alt="Victor"
        /> */}
        <Container>
          <Row className="align-items-center justify-content-center py-lg-5">
            <Col sm={9} md={4}>
              <div className="p-md-4">
                <img
                  className="w-100 hci_img px-5 px-lg-4"
                  src={capital}
                  alt="capital"
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <p className="ff_karla fw_400 fs_16 mt-4 mt-md-0 capital_text  pe-lg-2 text_align_justify m-0">
                  H Capital International is a privately held investment,
                  development, and advisory firm specializing in real assets -
                  real estate and infrastructure. Our expertise includes
                  developing, investing, and providing advise across a diverse
                  range of assets and transactions.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Capital;
