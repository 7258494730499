import React from "react";
import Login from "./Login";

const Log = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default Log;
