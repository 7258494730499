import React, { useRef } from "react";
// import emailjs from "@emailjs/browser";
import { useState } from "react";
import emailjs, { init } from "@emailjs/browser";
import swal from "sweetalert";
import { Container, Form } from "react-bootstrap";
import form_img from "../assets/image/png/form_img.png";
import window from "../assets/image/png/window.png";
import { Link } from "react-router-dom";

init("8CShN3O81gfqqRGaY");

function Login() {
  const initialState = {
    name: "",
    lastname: "",
    email: "",
  };
  const [letsCreate, setLetsCreate] = useState(initialState);
  const SubmitBtn = document.getElementById("submit-btn");
  const [isLoader, setLoader] = useState(false);
  const handleSubmitLetsCreate = (event) => {
    event.preventDefault();
    setLoader(true);
    SubmitBtn.innerHTML = "Sending";

    emailjs
      .send("service_t47btju", "template_afk0oqn", {
        name: letsCreate.name,
        lastname: letsCreate.lastname,
        email: letsCreate.email,
      })

      .then(
        (result) => {
          console.log(result);
          setLoader(false);
          setLetsCreate(initialState);
          swal({
            title: "Successfully Sent",
            text: "We will get in touch shortly",
            icon: "success",
          });
          SubmitBtn.innerHTML = "Log In";
        },
        (error) => {
          setLoader(false);
          console.log(error);
          swal({
            title: "Error!",
            text: "Failed to submit form! Please Try again.",
            icon: "error",
          });
          SubmitBtn.innerHTML = "Log In";
        }
      );
  };
  console.log(letsCreate);
  return (
    <>
      <section className=" min-vh-100 d-flex flex-column bg_login">
        <div className="bg_blue py-4">
          <h2 className="fs_20 fw_700 ff_karla text-white text-center mb-0">
            Real Assets beyond Real Estate
          </h2>
        </div>
        <div className=" d-flex justify-content-center flex-grow-1 flex-column">
          {" "}
          <Container>
            <div className="form_box mx-auto my-5 px-3 px-lg-5">
              <div className=" d-flex justify-content-center">
                <Link to="/">
                  <img src={form_img} alt="form_img" />
                </Link>
              </div>
              <p className="ff_karla fw_400 fs_16 mb-0 text-black pt-3 text-center">
                Please enter your email and password to proceed
              </p>
              <Form onSubmit={(event) => handleSubmitLetsCreate(event)}>
                <div className="mt-4 d-flex flex-column">
                  <label
                    className="ff_poppins fw_400 fs_18 text-black
                "
                  >
                    First Name
                  </label>
                  <input
                    className="p-3 mt-2 form outline_none"
                    placeholder="Your First Name"
                    required
                    value={letsCreate.name}
                    onChange={(event) =>
                      setLetsCreate({ ...letsCreate, name: event.target.value })
                    }
                    type="text"
                    id="name"
                    name="Name"
                  ></input>
                </div>
                <div className="mt-4 d-flex flex-column">
                  <label
                    className="ff_poppins fw_400 fs_18 text-black
                "
                  >
                    Last Name
                  </label>
                  <input
                    className="p-3 mt-2 form outline_none"
                    placeholder="Your Last Name"
                    required
                    value={letsCreate.lastname}
                    onChange={(event) =>
                      setLetsCreate({
                        ...letsCreate,
                        lastname: event.target.value,
                      })
                    }
                    type="text"
                    id="Phone"
                    name="lastname"
                  ></input>
                </div>
                <div className="mt-4 d-flex flex-column">
                  <label
                    className="ff_poppins fw_400 fs_18 text-black
                "
                  >
                    Email ID
                  </label>
                  <input
                    className="p-3 mt-2 form  outline_none"
                    placeholder="user@example.com"
                    required
                    value={letsCreate.email}
                    onChange={(event) =>
                      setLetsCreate({
                        ...letsCreate,
                        email: event.target.value,
                      })
                    }
                    type="email"
                    id="Email"
                    name="Email"
                  ></input>
                </div>
                <p className="ff_karla fw_500 fs_16 mb-0 mt-4 text-center text_blue">
                  Forgot your Password?
                </p>
                <div className="text-center mt-4">
                  <button
                    id="submit-btn"
                    type="submit"
                    value="Send"
                    className="request_btn ff_karla fw_700 fs_16 text-white w-100"
                  >
                    Log In
                  </button>
                </div>
              </Form>

              <div className="d-flex justify-content-center align-items-end mt-3 pb-5">
                <p className="mb-0 ff_karla fw_700 fs_16 text-black mt-3 d-inline text-center">
                  ©2023 Powered by Microsoft
                </p>
                <img className="ps-2" src={window} alt="window" />
              </div>

              {/* <p
                  className="ff_karla fw_500 fs_32 mb-0 clr_gray 
                "
                >
                  Microsoft
                </p> */}
            </div>
          </Container>
        </div>
      </section>
    </>
  );
}

export default Login;
