import React, { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap, { Linear } from "gsap";
import { Container } from "react-bootstrap";
import advisors from "../assets/image/png/advisors.png";
import assets from "../assets/image/png/assets.png";
import cleantech from "../assets/image/png/cleantech.png";
import homes from "../assets/image/png/homes.png";
import properties from "../assets/image/png/properties.png";
import Victor1 from "../assets/image/png/Vector1.png";
import { Link } from "react-router-dom";

const Info = () => {
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".info_section",
          start: "top 60%",
          end: "bottom top",
          // markers: true,
        },
      })
      .fromTo(
        ".advisors",
        {
          scale: 0.1,
          visibility: "hidden",
        },
        {
          scale: 1,
          visibility: "visible",
          stagger: 0.2,
        },
        "-=0.5"
      )
      .fromTo(
        ".info_btn",
        {
          scale: 0.5,
        },
        {
          scale: 1,
          visibility: "visible",
        }
      );
  }, []);
  return (
    <>
      <section className="overflow- hidden pt-5 position-relative info_section">
        {/* <img
          className="position-absolute animation_translate top-0 end-0  d-md-flex d-none"
          src={Victor1}
          alt="Victor"
        /> */}
        <Container>
          <div className="d-flex align-items-center justify-content-between   max_width overflow_auto mx-auto">
            <div className="me-5  me-md-0 advisors capital_hover">
              <div className="tooltiptext ">
                <p className="ff_karla fw_700 fs_20 mb-0 ">Capital Advisors</p>
                <p className="ff_karla fw_300 fs_16 mb-0  mt-2">
                  Capital Advisors is an advisory firm, providing expert advice
                  to clients on their most complex transactions. We focus on
                  delivering innovative solutions and exceptional service. Our
                  team has a proven track record of success, making us a trusted
                  advisor.
                </p>
              </div>
              <img className="wh_advisors " src={advisors} alt="advisors" />
            </div>
            <div className="me-5  me-md-0 advisors capital_hover">
              <div className="tooltiptext ">
                <p className="ff_karla fw_700 fs_20 mb-0 ">Capital Assets</p>
                <p className="ff_karla fw_300 fs_16 mb-0  mt-2">
                  Capital Assets is the infrastructure investment arm of HCI,
                  with a specialization in developing and investing in key
                  digital infrastructure, including data centers and 5G wireless
                  infrastructure such as small cells. Our primary objective is
                  to support the growth and expansion of digital technologies
                  and transformation in Nigeria.
                </p>
              </div>
              <img className="wh_assets" src={assets} alt="assets" />
            </div>
            <div className="me-5  me-md-0 advisors capital_hover">
              <div className="tooltiptext ">
                <p className="ff_karla fw_700 fs_20 mb-0 ">Capital Cleantech</p>
                <p className="ff_karla fw_300 fs_16 mb-0  mt-2">
                  Capital Cleantech is driving the transition towards clean
                  energy by providing commercial and industrial clients in
                  Nigeria with solar energy generation and energy storage
                  solutions. Through the development, acquisition, and operation
                  of various technologies, our focus is to accelerate the
                  adoption of clean energy; making it more accessible and
                  cost-effective for businesses in Nigeria.
                </p>
              </div>
              <img className="wh_cleantech" src={cleantech} alt="cleantech" />
            </div>
            <div className="me-5  me-md-0 advisors capital_hover">
              <div className="tooltiptext">
                <p className="ff_karla fw_700 fs_20 mb-0 ">Capital Homes</p>
                <p className="ff_karla fw_300 fs_16 mb-0  mt-2">
                  Capital homes is the residential real estate development
                  company arm of HCI, we focus on ultra-luxury residential
                  properties. We specialize on the development of high-end homes
                  and apartments, in Lagos. Woking with a team of experienced
                  architects, designers, and builders, we bring a wealth of
                  knowledge and expertise to every project. Our focus is on
                  creating homes that are not only stylish, but also
                  energy-efficient and environmentally friendly.
                </p>
              </div>
              <img className="wh_homes" src={homes} alt="homes" />
            </div>
            <div className="me-5  me-md-0 advisors capital_hover">
              <div className="tooltiptext ">
                <p className="ff_karla fw_700 fs_20 mb-0 ">
                  Capital Properties
                </p>
                <p className="ff_karla fw_300 fs_16 mb-0  mt-2">
                  Capital Properties is a leading commercial real estate
                  development company, providing innovative and sustainable
                  solutions to clients across the real estate spectrum,
                  including Retail, Office, Hospitality, Industrial and
                  Healthcare. With a team of experienced professionals, we offer
                  a full range of services, from site selection and acquisition
                  to development and construction.
                </p>
              </div>
              <img
                className="wh_properties"
                src={properties}
                alt=" properties"
              />
            </div>
          </div>

          <span className="d-flex align-items-center justify-content-center py-5 mt-md-4 ">
            <Link to="/Info">
              <span className=" ">
                <span className="info_btn text-white ff_karla fw_700 fs_16  ">
                  Info
                </span>
              </span>
            </Link>
          </span>
        </Container>
      </section>
    </>
  );
};

export default Info;
