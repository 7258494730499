import React from "react";
import footer_img from "../assets/image/png/footer_img.png";

function Footer() {
  return (
    <>
      <section className="bg_blue py-3 d-flex justify-content-center">
        <img className="cursor_pointer" src={footer_img} alt="footer_img" />
      </section>
    </>
  );
}

export default Footer;
