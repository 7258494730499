import React from "react";
import International from "./International";

const Estate = () => {
  return (
    <>
      <International />
    </>
  );
};

export default Estate;
