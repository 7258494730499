import React, { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { Container } from "react-bootstrap";
import logo from "../assets/image/png/logo.png";
import heroBg from "../assets/hero-bg.mp4";

function Header() {
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".hero_text",
          start: "top 40%",
          // markers: true,
          scrub: 1,
        },
      })
      .to(".hero_vid", {
        scale: 1.3,
      })
      .to(".hero_text", {
        marginTop: "-100px",
      });
  }, []);
  return (
    <>
      <section className=" min_vh_xl_100 d-flex flex-column overflow-hidden">
        <Container>
          <div className="py-3 text-center">
            <img src={logo} alt="logo" />
          </div>
        </Container>{" "}
        <div
          style={{ zIndex: "1" }}
          className=" position-relative  flex-grow-1 d-flex flex-column justify-content-center overflow-hidden"
        >
          <video
            style={{ zIndex: "-1", objectFit: "cover" }}
            className="w-100 hero_vid h-100 position-absolute top-0 start-0"
            muted
            autoPlay
            loop
          >
            <source src={heroBg} type="video/mp4"></source>
          </video>
          <Container>
            <h2 className="ff_karla hero_text fw_800 fs_73 text-white mw_770 mx-auto text-center">
              Real Assets Beyond Real Estate
            </h2>
          </Container>
        </div>
      </section>
    </>
  );
}

export default Header;
