import React from "react";
import BackToTop from "./BackToTop";
import Capital from "./Capital";
import Clients from "./Clients";
import Footer from "./Footer";
import Header from "./Header";
import Info from "./Info";
import Service from "./Service";
import Trust from "./Trust";

const Main = () => {
  return (
    <>
      <Header />
      <Capital />
      <Info />
      <Clients />
      <Trust />
      <Service />
      <Footer />
      <BackToTop />
    </>
  );
};

export default Main;
